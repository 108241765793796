/** @jsx jsx */
import { graphql } from 'gatsby'
import { FC, useState } from 'react'
import { Box, jsx, Text, Container, Grid } from 'theme-ui'

import Halo from '../components/halo'
import PageFrame from '../components/page'
import CardItem from '../components/nftcard'

import CardDetailFrame from '../components/carddetail'

export interface CardsPageProps {
  data: any
}

// const ContestPage: FC<ContestPage> = ({ data }) => {

const CardsPage: FC<CardsPageProps> = ({ data }) => {
  const allCardsYaml = data.allCardsYaml.nodes

  const [showCard, setShowCard] = useState<Boolean>(false)
  const [card, setCard] = useState<any>()
  /**
   * Show a card
   */
  const doShowCard = (_id: any) => {
    setShowCard(!showCard)
    setCard(_id)
  }

  const toggleCard = (_id: any) => {
    setShowCard(!showCard)
  }

  return (
    <PageFrame variant="box.plateTint3" invert={false} alt={false} adjustHeight={'0'}>
      <Halo title="All Cards" url="https://nft.rage.fan/cards" />
      <Box sx={{ bg: 'base', mt: 0 }}>
        <Box sx={{ py: 2, mx: 'auto', pt: 7, pb: 0 }}>
          <Container mb={5} sx={{ px: [5, 0, 0] }}>
            <Text as="h1" variant="sectionTitle" sx={{ m: 0, p: 0 }}>
              All Cards
            </Text>
          </Container>
        </Box>
        <Box sx={{ pt: 0, pb: 0 }}>
          <Container mb={0} sx={{ px: [5, 0, 0] }}>
            <Grid
              variant="box.plateGrid"
              gap={4}
              columns={[1, 2, 4]}
              sx={{
                mt: 2,
                mb: 2,
                mx: [6, 0, 0],
                p: [4, 0, 0],
                flexDirection: ['column', 'row', 'row'],
                display: ['block', 'grid', 'grid'],
              }}
            >
              {allCardsYaml &&
                allCardsYaml.map((x: any) => <CardItem image={x.image} {...x} card={x} onShowCard={doShowCard} />)}
            </Grid>
          </Container>
        </Box>
      </Box>
    </PageFrame>
  )
}

export default CardsPage

export const pageQuery = graphql`
  query AllCardsQuery {
    allCardsYaml {
      nodes {
        image
        id
        count
        desc
        coin_img
        price
        title
      }
    }
  }
`
